import { useState, useEffect } from "react";
import styles from "./List.module.scss";
import { Link } from "react-router-dom";

export default function List() {
  const [cars, setCars] = useState([]);

  const getCars = async () => {
    try {
      console.log(process.env.REACT_APP_PROXY);
      const { data } = fetch(`${process.env.REACT_APP_PROXY}/cars`)
        .then((res) => res.json())
        .then((res) => {
          setCars(res.data.results);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getCars();
  }, []);

  return (
    <div className={styles.list}>
      <div>
        <span>Model</span>
        <span>Offer link</span>
      </div>
      {cars?.map((obj, i) => (
        <div key={obj.url}>
          <a href={obj.url} target="blank">
            {i + 1}. {obj.properties.Name.title[0].plain_text}
          </a>
          <Link to={`/offer/${obj.id}`}>Link To Offer</Link>
        </div>
      ))}
    </div>
  );
}
