import {useCallback, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Offer.module.scss";
import Footer from "../../Components/Footer";
import Calculator from "../../Components/Calculator";
import Header from "../../Components/Header";
import MnuMob from "../../Components/MnuMob";
import Slider from "react-slick";
import debounce from "lodash.debounce";
import { openMobMnu, openModalForm} from "../../redux/slices/carsSlice"


function SampleNextArrow(props) {
  const {  onClick } = props;
  return (
    <div
      className="nextArrow"
      onClick={onClick}
    >
      <img src="/img/slb-rightBig.png" alt="" className="shadowArr"/>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
    className="prevArrow"
      onClick={onClick}
    > <img src="/img/slb-leftBig.png" alt="" className="shadowArr" /></div>
  );
}

export default function Offer() {
  const bg = useSelector((state) => state.cars.bg);
  const { id } = useParams();
  const comunityRef = useRef();
  const [imageIndex, setImageIndex] = useState(0);


  const onWheelSlider = useCallback(
    debounce((e) => {
      console.log("debounce");
      if (!comunityRef.current) return;

      if (e.deltaX > 0) {
        comunityRef.current.slickNext();
      } else if (e.deltaX < 0) {
        comunityRef.current.slickPrev();
      }
    }, 20),
    [],
  );

  const slidersImg = [
    "/img/sl1.jpg",
    "/img/sl2.jpeg",
    "/img/sl3.jpeg",
    "/img/sl4.jpeg",
    "/img/sl5.jpeg",
    "/img/sl6.jpeg",
    "/img/sl7.jpeg",
    "/img/sl8.jpeg",
  ]

  const comunitySlider = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2500,
    slidesToShow: 3,
    lazyLoad: true,
    slidesToScroll: 1,
    centerPadding: 0,
    dots: true,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 992,
        settings: {
         // className: "center",
          slidesToShow: 1,
          centerPadding: "24px",
          centerMode: true,
        },
      },
    ],
  };


  return (
    <>
    <MnuMob />
      <Header />
      <div className={styles.pagerWrapper}>
      <div className={styles.block_1} style={{ backgroundImage: `url(${bg})` }}>
        <h1>Прозора економіка в деталях</h1>
      </div>
      <div className="ApiWrap">
        <Calculator id={id} />
      </div>

      <section className={styles.notice1}>
        <div className={styles.imgPart}>
          <img src="../img/notice1.jpeg" alt="" />
        </div>
        <div className={styles.rightPart}>
          <h2>Drivovo - ви фабрика автомобільного щастя!</h2>
          <div className={styles.textBlock}>
            Перший в моїй практиці клієнт-орієнтований сервіс в Україні. Хто не знає — все просто —
            це коли ти маєш авто та емоції, про які завжди мріяв, але без потреби купувати.
            Насправді це більше ніж просто послуга — ти частина драйвового ком‘юніті, спілкуєшся з
            прогресивними людьми, обмінюєшся авто, отримуєш нові і нові враження.
          </div>
          <div className={styles.notice1_flex}>
            <div>
              <p className={styles.notice1_name}>Alexandr Shevchenko</p>
              <p className={styles.notice1_descr}>
                Board Member/Chief Operating Officer у{" "}
                <a href="https://www.figma.com/exit?url=https%3A%2F%2Fwww.facebook.com%2Fdevoxsoftware">
                  Devox Software
                </a>{" "}
              </p>
            </div>

            <a
              href="https://www.figma.com/exit?url=https%3A%2F%2Fwww.facebook.com%2FDrivovoClub%2Freviews"
              target="blank">
              читати усі відгуки
            </a>
          </div>
        </div>
      </section>

      <section className={styles.titleSection}>
        <h2>
          Приєднуйся до ком'юніті Drivovo <span>Pride</span>
        </h2>
        <p>Це як потужний двигун та комфортний салон життя.</p>
      </section>

      {/* <section className={styles.photos}>
        <img src="../img/pkImg.jpeg" className={styles.pkImg} alt="" />
        <img src="../img/mobImg.jpeg" className={styles.mobImg} alt="" />
      </section> */}

      <div onWheel={onWheelSlider}>
        <Slider {...comunitySlider} ref={comunityRef} className="carComunitySlider">
          {slidersImg?.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"} key={idx}>
              <img src={img} alt={img} />
            </div>
          ))}

        </Slider>
      </div>

      <section className={styles.titleSection}>
        <h2>
          Будуємо стосунки довжиною в <span>Життя</span>
        </h2>
        <p>Та надаємо потужний бустер для для розвитку. </p>
      </section>

      <section className={styles.notice2}>
        <div className={styles.imgPart}>
          <img src="../img/seo.jpeg" alt="" />
        </div>
        <div className={styles.leftPart}>
          <h2>
            Наша ціль — звільнити час та думки людей, які розвивають бізнес та технології, надати їм
            швидкості та комфорту в житті.
          </h2>

          <div>
            <p className={styles.notice2_name}>Макс Соловйов</p>
            <p className={styles.notice2_descr}>CEO & Co-Founder DRIVOVO</p>
          </div>
        </div>
      </section>
      <div className={styles.driveEasy}>Drive IT easy!</div>
      <Footer />
    </div>


    </>
   
  );
}
