/* eslint-disable no-template-curly-in-string */
import { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCars, setBg } from "../../redux/slices/carsSlice";
import Slider from "react-slick";
import styles from "./Offer.module.scss";
import debounce from "lodash.debounce";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Offer() {
  //const [cars, setCars] = useState([]);
  const [changedCar, setChangedCar] = useState(null);
  const [images, setImages] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [startFund, setStartFund] = useState(0);
  const [ownerThreeYearsCost, setOwnerThreeYearsCost] = useState(null);
  const cars = useSelector((state) => state.cars.cars);
  const sliderRef = useRef();

  const dispatch = useDispatch();
  const startFundCount = () => {
    const result = (
      parseInt(changedCar?.properties.car_price_ex_showroom.number) +
      parseFloat(changedCar?.properties.pension_fund.formula.number.toFixed(2)) +
      parseFloat(changedCar?.properties.insurance_1_year.formula.number) +
      parseFloat(changedCar?.properties.registration.formula.number) +
      parseFloat(changedCar?.properties.luxury_tax.number) +
      parseFloat(changedCar?.properties.tinted_glass.formula.number) +
      parseFloat(changedCar?.properties.tires.number) +
      parseFloat(changedCar?.properties.carpets.number) +
      parseFloat(changedCar?.properties.safety_net.formula.number) +
      parseFloat(changedCar?.properties.radiator_protection.number) +
      parseFloat(changedCar?.properties.armored_film.number)
    )
      //parseFloat(changedCar?.properties.insurance_2_year.formula.number)
      .toFixed(2);
    setStartFund(result);
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <img src="img/rightAr.svg" alt="" />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src="img/rightAr.svg" alt="" />
      </div>
    );
  };

  const imgs = [
    "img/car.jpeg",
    "img/car2.jpeg",
    "img/notice1.jpeg",
    "img/car.jpeg",
    "img/car2.jpeg",
    "img/notice1.jpeg",
  ];

  const settings = {
    infinite: true,
    // speed: 800,
    // autoplay: true,
    // autoplaySpeed: 2000,
    slidesToShow: 3,
    lazyLoad: true,
    slidesToScroll: 1,
    centerPadding: 0,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const setElementSlider = (index) => {
    console.log(index);
    // const deleteClassEl = document.querySelector(".slick-active.prevElement");
    // if (deleteClassEl) {
    //   deleteClassEl.classList.remove("prevElement");
    // }

    // let prevEl = document.querySelector(".slick-current").previousSibling;
    // prevEl.classList.add("prevElement");
    // console.log(prevEl);
  };

  const numberWithCommas = (x) => {
    if (x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      return x;
    }
    return;
  };

  // Вартість володіння автомобілем за 3 роки
  const ownThreeYearsCount = () => {
    const result = (
      parseFloat(startFund) +
      parseFloat(((startFund / 100) * 21).toFixed(2)) +
      parseFloat(changedCar?.properties.tire_service.formula.number) +
      parseFloat(changedCar?.properties.luxury_tax_2_years.number) +
      parseFloat(changedCar?.properties.osago_2_year.formula.number) +
      parseFloat(changedCar?.properties.maintenance.formula.number) +
      //parseFloat(changedCar?.properties.armored_film.number) +
      parseFloat(changedCar?.properties.insurance_2_year.formula.number)
    ).toFixed(2);

    console.log(result);
    setOwnerThreeYearsCost(result);
  };

  useEffect(() => {
    dispatch(getCars());
  }, []);
  useEffect(() => {
    console.log(cars);
    // eslint-disable-next-line no-lone-blocks
    {
      cars && setChangedCar(cars[0]);
    }

    startFundCount();
  }, [cars]);

  useEffect(() => {
    startFundCount();
    dispatch(setBg(changedCar?.properties.Cover.files[0].file.url));
  }, [changedCar]);
  useEffect(() => {
    ownThreeYearsCount();
  }, [startFund]);

  const handlerChange = (e) => {
    console.log(e.target.value);
    console.log(cars);
    const currentCar = cars.find((obj) => obj.url === e.target.value);
    setChangedCar(currentCar);
    setImageIndex(0);
    console.log(currentCar);
    setImages(currentCar.properties.Photo.files);
    console.log(changedCar);
  };

  const onWheelSlider = useCallback(
    debounce((e) => {
      console.log("debounce");
      if (!sliderRef.current) return;

      if (e.deltaX > 0) {
        sliderRef.current.slickNext();
      } else if (e.deltaX < 0) {
        sliderRef.current.slickPrev();
      }
    }, 20),
    [],
  );

  return (
    <>
      <div className={styles.selectBox}>
        <select name="" id="" onChange={handlerChange}>
          <option value="">Вибери авто</option>
          {cars?.map((obj, i) => (
            <option key={obj.url} value={obj.url}>
              {obj.properties.Name.title[0].plain_text}
            </option>
          ))}
        </select>
      </div>

      {/* {changedCar?.properties.Cover.files[0]?.file?.url && (
        <img src={changedCar?.properties.Cover.files[0].file.url} width={600} />
      )} */}

      <div className="offerDescr">
        <h2>Драйвові призи переможцям</h2>
        <div className="descrBlock">
          Drivovo Meme Game складається з трьох турів (в онлайн версії 2 тури). Учасники діляться на
          2 команди, щоб змагатися, хто краще знає та розуміє меми. Серед команди переможців
          розігруються ключі на новеньке авто від Drivovo. Використовувати авто можна протягом
          вихідних. Учасники, що не виграли головний приз отримують можливість забронювати
          тест-драйв на 2 години на будь-яке авто з автопарку.
        </div>
      </div>

      <div onWheel={onWheelSlider}>
        <Slider {...settings} ref={sliderRef}>
          {images?.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"} key={idx}>
              <img src={img.file.url} alt={img} />
              {console.log(img)}
            </div>
          ))}
        </Slider>
      </div>

      {/* <Slider {...settings}>
        {imgs?.map((img, idx) => (
          <div className={idx === imageIndex ? "slide activeSlide" : "slide"}>
            <img src={img} alt={img} />
            {console.log(img)}
          </div>
        ))}
      </Slider> */}
      <h2>Вартість володіння автомобілем при купівлі за кеш</h2>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <span className={styles.tableTitle}>
            {changedCar?.properties.Name.title[0].plain_text}
          </span>
          <span className={styles.tableTitle}>Кеш</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість авто в салоні</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.car_price_ex_showroom.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Пенсійний фонд</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.pension_fund.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>КАСКО перший рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.insurance_1_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Автоцивілка перший рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.osago.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Реєстрація авто</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.registration.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Податок на розкіш</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.luxury_tax.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Балансова вартість автомобіля</span>
          <span className={styles.tableItem}>
            $
            {numberWithCommas(
              (
                parseInt(changedCar?.properties.car_price_ex_showroom.number) +
                parseFloat(changedCar?.properties.pension_fund.formula.number.toFixed(2)) +
                parseFloat(changedCar?.properties.insurance_1_year.formula.number) +
                parseFloat(changedCar?.properties.registration.formula.number) +
                parseFloat(changedCar?.properties.luxury_tax.number)
              ).toFixed(2),
            )}
          </span>
        </div>
        {/* ==================================================================================== */}
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Тонування</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tinted_glass.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Комплект гуми</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tires.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Килимки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.carpets.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Захисна сітка</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.safety_net.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Захист радіатора</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.radiator_protection.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Бронеплівка</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.armored_film.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Початкові вкладення в автомобіль</span>
          <span className={styles.tableItem}>${numberWithCommas(startFund)}</span>
        </div>
        {/* ==================================================================================== */}

        <div className={styles.tableRow}>
          <span className={styles.tableItem}>КАСКО другий та третій рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.insurance_2_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>ТО за три роки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.maintenance.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Автоцивілка другий та третій рік </span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.osago_2_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Податок на розкіш другий та третій рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.luxury_tax_2_years.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Шиномонтаж та зберігання за 3 роки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tire_service.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість власного капіталу за 3 роки (7%)</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(((startFund / 100) * 21).toFixed(2))}
          </span>
        </div>
        {/* ==================================================================================== */}
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Вартість володіння автомобілем за 3 роки</span>
          <span className={styles.tableItem}>${numberWithCommas(ownerThreeYearsCost)}</span>
        </div>

        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість володіння автомобілем на місяць </span>
          <span className={styles.tableItem}>
            ${numberWithCommas((ownerThreeYearsCost / 36).toFixed(2))}{" "}
          </span>
        </div>
      </div>

      <iframe className="meetings-wrapper" src="https://drivovo.com/meetings/"></iframe>
    </>
  );
}
