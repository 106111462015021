import { useState, useEffect, useRef, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Calculator.module.scss";
import { getCars, setBg } from "../../redux/slices/carsSlice";
import Slider from "react-slick";
import debounce from "lodash.debounce";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Calculator = memo( function Calculator({ id }) {
  //const [cars, setCars] = useState([]);
  const [changedCar, setChangedCar] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const [startFund, setStartFund] = useState(0);
  const [pricePerMonth, setPricePerMonth] = useState(0);
  const [persent, setPersent] = useState(0.4);
  const [drivovoPrice, setDrivovoPrice] = useState(0);
  const [lastPayment, setLastPayment] = useState(0);
  const [dopCost, setDopCost] = useState(0);
  const [ownerThreeYearsCost, setOwnerThreeYearsCost] = useState(null);
  const [images, setImages] = useState(null);
  const [pricePerMonth40, setPricePerMonth40] = useState(0);
  const [pricePerMonth50, setPricePerMonth50] = useState(0);
  const [pricePerMonthStandart, setPricePerMonthStandart] = useState(0);
  const [costDop40, setCostDop40] = useState(0);
  const [costDop50, setCostDop50] = useState(0);
  const [garantyStandart, setGarantyStandart] = useState(0);
  const [garanty, setGaranty] = useState(0);
  const sliderRef = useRef();
  const cars = useSelector((state) => state.cars.cars);
  const dispatch = useDispatch();

  const table1 = useRef();
  const table2 = useRef();
  //const table3 = useRef();
  const btn1 = useRef();
  const btn2 = useRef();
  //const btn3 = useRef();
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <img src="../img/rightAr.svg" alt="" />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <img src="../img/rightAr.svg" alt="" />
      </div>
    );
  };
  const settings = {
    infinite: true,
    // speed: 800,
    // autoplay: true,
    // autoplaySpeed: 2000,
    slidesToShow: 3,
    lazyLoad: true,
    slidesToScroll: 1,
    centerPadding: 0,
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 992,
        settings: {
         // className: "center",
          slidesToShow: 1,
          centerPadding: "24px",
          centerMode: true,
        },
      },
    ],
  };

  const widthDetect = () => {
    const w = window.innerWidth;
    if (w < 1024) {
      table1.current.classList.add("showTable");
      table2.current.classList.add("hideTable");
      //table3.current.classList.add("hideTable");
      btn1.current.classList.add("activeTab");
    }
    console.log(w);
  };
  const getCostPerDay = () => {
    const res = (
      (((parseFloat(drivovoPrice) / 36).toFixed(2) -
        (parseFloat(ownerThreeYearsCost) / 36).toFixed(2)) *
        37) /
      30
    ).toFixed(0);
    return res;
  };
  const handleTabs = (type) => {
    console.log(type);
    if (type == "t1") {
      table1.current.classList.remove("hideTable");
      table1.current.classList.add("showTable");
      table2.current.classList.add("hideTable");
      table2.current.classList.remove("showTable");
      //table3.current.classList.add("hideTable");
      //table3.current.classList.remove("showTable");
      btn1.current.classList.add("activeTab");
      btn2.current.classList.remove("activeTab");
      //btn3.current.classList.remove("activeTab");
    }
    if (type == "t2") {
      table2.current.classList.remove("hideTable");
      table2.current.classList.add("showTable");
      //table3.current.classList.add("hideTable");
      //table3.current.classList.remove("showTable");
      table1.current.classList.add("hideTable");
      table1.current.classList.remove("showTable");
      btn1.current.classList.remove("activeTab");
      btn2.current.classList.add("activeTab");
      //btn3.current.classList.remove("activeTab");
    }
    // if (type == "t3") {
    //   table1.current.classList.add("hideTable");
    //   table1.current.classList.remove("showTable");
    //   table2.current.classList.add("hideTable");
    //   table2.current.classList.remove("showTable");
    //   //table3.current.classList.remove("hideTable");
    //   //table3.current.classList.add("showTable");
    //   btn1.current.classList.remove("activeTab");
    //   btn2.current.classList.remove("activeTab");
    //   //btn3.current.classList.add("activeTab");
    // }
  };
  const numberWithCommas = (x) => {
    if (x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
      //console.log(x);
      return x;
    }
    return;
  };

  const startFundCount = () => {
    const result = (
      parseInt(changedCar?.properties.car_price_ex_showroom.number) +
      parseFloat(changedCar?.properties.pension_fund.formula.number.toFixed(2)) +
      parseFloat(changedCar?.properties.insurance_1_year.formula.number) +
      parseFloat(changedCar?.properties.registration.formula.number) +
      parseFloat(changedCar?.properties.luxury_tax.number) +
      parseFloat(changedCar?.properties.tinted_glass.formula.number) +
      parseFloat(changedCar?.properties.tires.number) +
      parseFloat(changedCar?.properties.carpets.number) +
      parseFloat(changedCar?.properties.safety_net.formula.number) +
      parseFloat(changedCar?.properties.radiator_protection.number) +
      parseFloat(changedCar?.properties.armored_film.number)
    )
      //parseFloat(changedCar?.properties.insurance_2_year.formula.number)
      .toFixed(2);
    console.log(result);
    setStartFund(result);
  };

  // Вартість володіння автомобілем за 3 роки
  const ownThreeYearsCount = () => {
    const result = (
      parseFloat(startFund) +
      parseFloat(((startFund / 100) * 21).toFixed(2)) +
      parseFloat(changedCar?.properties.tire_service.formula.number) +
      parseFloat(changedCar?.properties.luxury_tax_2_years.number) +
      parseFloat(changedCar?.properties.osago_2_year.formula.number) +
      parseFloat(changedCar?.properties.maintenance.formula.number) +
      //parseFloat(changedCar?.properties.armored_film.number) +
      parseFloat(changedCar?.properties.insurance_2_year.formula.number)
    ).toFixed(2);

    const dop = (
      parseInt(changedCar?.properties.car_price_ex_showroom.number) +
      parseFloat(changedCar?.properties.carpets.number) +
      parseFloat(changedCar?.properties.armored_film.number)
    ).toFixed(2);

    const last = dop * persent;

    const maxPrice = parseFloat(result) + parseFloat(result * 0.13);

    const perMonth = ((parseFloat(maxPrice) - parseFloat(last)) / 36).toFixed(0);

    setDopCost(dop); // вартість з доп
    setLastPayment(last); // останній платіж
    setDrivovoPrice(maxPrice.toFixed(0)); // ватість з викупом
    setPricePerMonth(perMonth); //місячна підписка
    setOwnerThreeYearsCost(result);

    const last40 = dop * persent;
    const perMonth40 = ((parseFloat(maxPrice) - parseFloat(last40)) / 36).toFixed(0);
    setCostDop40(last40);

    const last50 = dop * persent;
    const perMonth50 = ((parseFloat(maxPrice) - parseFloat(last50)) / 36).toFixed(0);
    setCostDop50(last50);
    // const last60 = dop * 0.6;
    // const perMonth60 = ((parseFloat(maxPrice) - parseFloat(last60)) / 36).toFixed(0);

    // setPricePerMonth40(perMonth40);
    // setPricePerMonth50(perMonth50);

    if (changedCar?.properties.car_price_ex_showroom.number > 50000) {
      const garanty1 = (maxPrice - last40) / (36 + 8);
      setGarantyStandart(garanty1 * 8);

      const garanty2 = (maxPrice - last50) / 36;
      setGaranty(garanty2 * 6);

      setPricePerMonthStandart((parseFloat(maxPrice - last40) / 36).toFixed(0));
      setPricePerMonth40((parseFloat(maxPrice - last40) / (36 + 8)).toFixed(0));
    } else {
      const garanty1 = (maxPrice - last40) / (36 + 6);
      setGarantyStandart(garanty1 * 6);

      const garanty2 = (maxPrice - last50) / 36;
      setGaranty(garanty2 * 5);

      setPricePerMonthStandart((parseFloat(maxPrice - last40) / 36).toFixed(0));
      setPricePerMonth40((parseFloat(maxPrice - last40) / (36 + 6)).toFixed(0));
    }
  };

  useEffect(() => {
    //window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(getCars());
    widthDetect();
  }, []);

  useEffect(() => {
    const currentCar = cars?.find((obj) => obj.id === id);
    console.log(currentCar);
    setChangedCar(currentCar);
    setImages(currentCar?.properties.Photo.files);
    if(currentCar?.properties.residual_value?.number !== 0){
      setPersent(currentCar?.properties.residual_value?.number)
      console.log(currentCar?.properties.residual_value?.number);
    }else{
      setPersent(0.4)
    }
    if(currentCar?.properties.bottom_cover.files[0]?.file?.url){
      dispatch(setBg(currentCar?.properties.Cover.files[0]?.file?.url));
    }
    
    startFundCount();
    
  }, [cars]);

  useEffect(() => {
    startFundCount();
    document.title = changedCar?.properties?.car_name.rich_text[0]?.plain_text;
    
  }, [changedCar]);

  useEffect(() => {
    ownThreeYearsCount();
   
  }, [startFund]);

  const onWheelSlider = useCallback(
    debounce((e) => {
      console.log("debounce");
      if (!sliderRef.current) return;

      if (e.deltaX > 0) {
        sliderRef.current.slickNext();
      } else if (e.deltaX < 0) {
        sliderRef.current.slickPrev();
      }
    }, 20),
    [],
  );
  console.log(changedCar);
  return (
    <>
      <div className="offerDescr">
        <h2>{changedCar?.properties.Title.rich_text[0]?.plain_text}</h2>
        <div className="descrBlock">
        {changedCar?.properties.Text.rich_text[0]?.plain_text}
        </div>
      </div>

      <div
       //onWheel={onWheelSlider}
       >
        <Slider {...settings} ref={sliderRef}>
          {images?.map((img, idx) => (
            <div className={idx === imageIndex ? "slide activeSlide" : "slide"} key={idx}>
              <img src={img.file.url} alt={img} />
             
            </div>
          ))}
        </Slider>
      </div>

<br /><br />

      <h2>Графік платежів</h2>
      <div className={styles.mobileTabs}>
        <button className={styles.tab} onClick={() => handleTabs("t1")} ref={btn1}>
          Стандартний графік
        </button>
        <button className={styles.tab} onClick={() => handleTabs("t2")} ref={btn2}>
          Персональний 1
        </button>
        {/* <button className={styles.tab} onClick={() => handleTabs("t3")} ref={btn3}>
          Drivovo Lease
        </button> */}
      </div>
      <div className={`${styles.table} ${styles.tableSchedule}`}>
        <div className={styles.tableSchedule_title}>Підписка Drivovo</div>
        <div className={`${styles.table_column} ${styles.table_column_first}`}>
          <div>Платежі</div>
          <div></div>
          <div></div>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
          <div>11</div>
          <div>12</div>
          <div>13</div>
          <div>14</div>
          <div>15</div>
          <div>16</div>
          <div>17</div>
          <div>18</div>
          <div>19</div>
          <div>20</div>
          <div>21</div>
          <div>22</div>
          <div>23</div>
          <div>24</div>
          <div>25</div>
          <div>26</div>
          <div>27</div>
          <div>28</div>
          <div>29</div>
          <div>30</div>
          <div>31</div>
          <div>32</div>
          <div>33</div>
          <div>34</div>
          <div>35</div>
          <div>36</div>
          <div>37</div>
          <div></div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_second}`}>
          <div>{window.innerWidth > 1024 ? "Платежі" : ""}</div>

          <div>Оплата першого місяця</div>
          <div>Гарантійний депозит</div>
          <div>Разом, перший платіж</div>
          <div>Оплата другого місяця</div>

          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Останній платіж, переоформлення</div>
          <div>Вартість володіння з урахуванням викупу</div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_40}`} ref={table1}>
          <div>{window.innerWidth > 1024 ? "Стандартний графік" : ""}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(garanty.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas((parseFloat(pricePerMonthStandart) + parseFloat(garanty)).toFixed(0))}
          </div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>

          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>
            {changedCar?.properties.car_price_ex_showroom.number > 50000
              ? "0"
              : `$${numberWithCommas(pricePerMonthStandart)}`}
          </div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>${numberWithCommas(costDop40.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_50}`} ref={table2}>
          <div>{window.innerWidth > 1024 ? "Персональний " : ""}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(garantyStandart.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas(
              (parseFloat(pricePerMonth40) + parseFloat(garantyStandart)).toFixed(0),
            )}
          </div>
          <div>${numberWithCommas(pricePerMonth40)}</div>

          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(costDop40.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div>
        {/* <div className={`${styles.table_column} ${styles.table_column_50}`} ref={table2}>
          <div>{window.innerWidth > 1024 ? "Персональний 1" : ""}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(garantyPerson.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas((parseFloat(pricePerMonth50) + parseFloat(garantyPerson)).toFixed(0))}
          </div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div></div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(costDop50.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div> */}
        {/* <div className={`${styles.table_column} ${styles.table_column_60}`} ref={table3}>
          <div>{window.innerWidth > 1024 ? "Drivovo Lease" : ""}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60 * 6)}</div>
          <div>${numberWithCommas(pricePerMonth60 * 7)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div></div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>$55,000 (???)</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div> */}
      </div>
   <br /><br />

        

      <div className={styles.selectBox}></div>
      <h2>Вартість володіння автомобілем при купівлі за кеш</h2>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <span className={styles.tableTitle}>
            {changedCar?.properties?.car_name?.rich_text[0]?.plain_text}
          </span>
          <span className={styles.tableTitle}>Кеш</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість авто в салоні</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.car_price_ex_showroom.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Пенсійний фонд</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.pension_fund.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>КАСКО перший рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.insurance_1_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Автоцивілка перший рік</span>
          <span className={styles.tableItem}>
            {" "}
            ${numberWithCommas(changedCar?.properties.osago.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Реєстрація авто</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.registration.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Податок на розкіш</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.luxury_tax.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Балансова вартість автомобіля</span>
          <span className={styles.tableItem}>
            $
            {numberWithCommas(
              (
                parseInt(changedCar?.properties.car_price_ex_showroom.number) +
                parseFloat(changedCar?.properties.pension_fund.formula.number.toFixed(2)) +
                parseFloat(changedCar?.properties.insurance_1_year.formula.number) +
                parseFloat(changedCar?.properties.registration.formula.number) +
                parseFloat(changedCar?.properties.luxury_tax.number)
              ).toFixed(2),
            )}
          </span>
        </div>
        {/* ==================================================================================== */}
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Тонування</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tinted_glass.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Комплект гуми</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tires.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Килимки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.carpets.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Захисна сітка</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.safety_net.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Захист радіатора</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.radiator_protection.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Бронеплівка</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.armored_film.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Початкові вкладення в автомобіль</span>
          <span className={styles.tableItem}>${numberWithCommas(startFund)}</span>
        </div>
        {/* ==================================================================================== */}

        <div className={styles.tableRow}>
          <span className={styles.tableItem}>КАСКО другий та третій рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.insurance_2_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>ТО за три роки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.maintenance.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Автоцивілка другий та третій рік </span>
          <span className={styles.tableItem}>
            {" "}
            ${numberWithCommas(changedCar?.properties.osago_2_year.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Податок на розкіш другий та третій рік</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.luxury_tax_2_years.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Шиномонтаж та зберігання за 3 роки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(changedCar?.properties.tire_service.formula.number.toFixed(2))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість власного капіталу за 3 роки (7%)</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(((startFund / 100) * 21).toFixed(2))}
          </span>
        </div>
        {/* ==================================================================================== */}
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Вартість володіння автомобілем за 3 роки</span>
          <span className={styles.tableItem}>${numberWithCommas(ownerThreeYearsCost)}</span>
        </div>

        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість володіння автомобілем на місяць </span>
          <span className={styles.tableItem}>
            ${numberWithCommas((ownerThreeYearsCost / 36).toFixed(2))}{" "}
          </span>
        </div>
      </div>

      <h2>Вартість підписки на авто від Drivovo</h2>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <span className={styles.tableTitle}>
            {changedCar?.properties.car_name.rich_text[0].plain_text}
          </span>
          <span className={styles.tableTitle}>Підписка Drivovo</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість підписки на місяць</span>
          <span className={styles.tableItem}>${numberWithCommas(pricePerMonthStandart)}</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Гарантійний депозит</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(parseFloat(garanty).toFixed(0))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>
            Реєстрація авто + оплата податків (ПФ, податок на розкіш){" "}
          </span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>КАСКО+ОСАГО за 3 роки</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>
            Бронеплівка, тонування, захистна сітка, захист радіатора, килимки
          </span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Технічне обслуговування</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Драйвер ТО, СТО</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Зимова гума, зберігання, шиномонтаж</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Супровід страхових випадків, підмінне авто</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Доступ в комʼюніті Drivovo Pride</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Дисконтний клуб Drivovo Loyalty </span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Trade IN твого старого авто</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Інвестиції в Drovovo 10-12% річних в валюті</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Тестування різних авто з Drivovo Hub</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Захист кредитної історії</span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Upgrade | Downgrade авто за підпискою </span>
          <span className={styles.tableItem}>
            <img src="../img/check.svg" alt="" className={styles.ico} />
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Залишкова вартість (викуп авто) </span>
          <span className={styles.tableItem}>${numberWithCommas(lastPayment.toFixed(0))}</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Період дії договору </span>
          <span className={styles.tableItem}>36 місяців</span>
        </div>

        {/* ==================================================================================== */}
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Вартість володіння автомобілем за 3 роки</span>
          <span className={styles.tableItem}>${numberWithCommas(drivovoPrice)}</span>
        </div>
      </div>

      {/* ==================================================================================== */}
      <h2>Порівняння купівлі авто за кеш VS підписка Drivovo</h2>
      <div className={`${styles.table} ${styles.tableTrio}`}>
        <div className={styles.tableRow}>
          <span></span>
          <span className={styles.tableTitle}>Кеш</span>
          <span className={styles.tableTitle}>Drivovo</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Початкові вкладення</span>
          <span className={styles.tableItem}>${numberWithCommas(startFund)}</span>
          <span className={`${styles.tableItem} ${styles.accentCell}`}>
            $
            {numberWithCommas((parseFloat(pricePerMonthStandart) + parseFloat(garanty)).toFixed(0))}
          </span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Експлуатаційні витрати за 3 роки</span>
          <span className={styles.tableItem}>
            ${numberWithCommas((ownerThreeYearsCost - startFund).toFixed(0))}
          </span>
          <span className={styles.tableItem}>Враховані в підписку</span>
        </div>

        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість викупу через 3 роки</span>
          <span className={styles.tableItem}>0</span>
          <span className={styles.tableItem}>${numberWithCommas(lastPayment.toFixed(0))}</span>
        </div>
        <div className={styles.tableRow}>
          <span className={styles.tableItem}>Вартість володіння через 3 роки</span>
          <span className={styles.tableItem}>${numberWithCommas(ownerThreeYearsCost)}</span>
          <span className={styles.tableItem}>
            ${numberWithCommas(parseInt(drivovoPrice).toFixed(0))}
          </span>
        </div>

        {/* ==================================================================================== */}
        <div className={styles.tableRowSubTitle}>
          <span className={styles.tableItem}>Вартість володіння в місяць </span>
          <span className={styles.tableItem}>
            ${numberWithCommas((parseFloat(ownerThreeYearsCost) / 36).toFixed(2))}
          </span>
          <span className={styles.tableItem}>
            ${numberWithCommas((parseFloat(drivovoPrice) / 36).toFixed(0))}
          </span>
        </div>
      </div>






      <div className={styles.infiPanel}>
        <h3>
          Різниця вартості володіння купівля/підписка за 3 роки <span>13%</span>{" "}
        </h3>
        <p>
          Або <span>{getCostPerDay()}</span> грн на день — це як пригостити обідом друга😋
        </p>
      </div>

      {/* ==================================================================================== */}
      {/* <h2>Графік платежів</h2>
      <div className={styles.mobileTabs}>
        <button className={styles.tab} onClick={() => handleTabs("t1")} ref={btn1}>
          Стандартний графік
        </button>
        <button className={styles.tab} onClick={() => handleTabs("t2")} ref={btn2}>
          Персональний 1
        </button> */}
        {/* <button className={styles.tab} onClick={() => handleTabs("t3")} ref={btn3}>
          Drivovo Lease
        </button> */}
      {/* </div>
      <div className={`${styles.table} ${styles.tableSchedule}`}>
        <div className={styles.tableSchedule_title}>Підписка Drivovo</div>
        <div className={`${styles.table_column} ${styles.table_column_first}`}>
          <div>Платежі</div>
          <div></div>
          <div></div>
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>
          <div>9</div>
          <div>10</div>
          <div>11</div>
          <div>12</div>
          <div>13</div>
          <div>14</div>
          <div>15</div>
          <div>16</div>
          <div>17</div>
          <div>18</div>
          <div>19</div>
          <div>20</div>
          <div>21</div>
          <div>22</div>
          <div>23</div>
          <div>24</div>
          <div>25</div>
          <div>26</div>
          <div>27</div>
          <div>28</div>
          <div>29</div>
          <div>30</div>
          <div>31</div>
          <div>32</div>
          <div>33</div>
          <div>34</div>
          <div>35</div>
          <div>36</div>
          <div>37</div>
          <div></div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_second}`}>
          <div>{window.innerWidth > 1024 ? "Платежі" : ""}</div>

          <div>Оплата першого місяця</div>
          <div>Гарантійний депозит</div>
          <div>Разом, перший платіж</div>
          <div>Оплата другого місяця</div>

          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>Останній платіж, переоформлення</div>
          <div>Вартість володіння з урахуванням викупу</div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_40}`} ref={table1}>
          <div>{window.innerWidth > 1024 ? "Стандартний графік" : ""}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(garanty.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas((parseFloat(pricePerMonthStandart) + parseFloat(garanty)).toFixed(0))}
          </div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>

          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>${numberWithCommas(pricePerMonthStandart)}</div>
          <div>
            {changedCar?.properties.car_price_ex_showroom.number > 50000
              ? "0"
              : `$${numberWithCommas(pricePerMonthStandart)}`}
          </div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>0</div>
          <div>${numberWithCommas(costDop40.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div>
        <div className={`${styles.table_column} ${styles.table_column_50}`} ref={table2}>
          <div>{window.innerWidth > 1024 ? "Персональний " : ""}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(garantyStandart.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas(
              (parseFloat(pricePerMonth40) + parseFloat(garantyStandart)).toFixed(0),
            )}
          </div>
          <div>${numberWithCommas(pricePerMonth40)}</div>

          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(pricePerMonth40)}</div>
          <div>${numberWithCommas(costDop40.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div> */}
        {/* <div className={`${styles.table_column} ${styles.table_column_50}`} ref={table2}>
          <div>{window.innerWidth > 1024 ? "Персональний 1" : ""}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(garantyPerson.toFixed(0))}</div>
          <div>
            $
            {numberWithCommas((parseFloat(pricePerMonth50) + parseFloat(garantyPerson)).toFixed(0))}
          </div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div></div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(pricePerMonth50)}</div>
          <div>${numberWithCommas(costDop50.toFixed(0))}</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div> */}
        {/* <div className={`${styles.table_column} ${styles.table_column_60}`} ref={table3}>
          <div>{window.innerWidth > 1024 ? "Drivovo Lease" : ""}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60 * 6)}</div>
          <div>${numberWithCommas(pricePerMonth60 * 7)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div></div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>${numberWithCommas(pricePerMonth60)}</div>
          <div>$55,000 (???)</div>
          <div>${numberWithCommas(drivovoPrice)}</div>
        </div> */}
      {/* </div> */}
      <iframe className="meetings-wrapper" src="https://drivovo.com/meetings/"></iframe>
    </>
  );
})

export default Calculator;
