import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  cars: [],
  bg: "",
  mobMnu: false
};

export const getCars = createAsyncThunk("cars/getCars", async () => {
  try {
    console.log(process.env.REACT_APP_PROXY);
    const { data } = await axios(`${process.env.REACT_APP_PROXY}/cars`);

    return data.data.results;
  } catch (e) {
    console.log(e.message);
  } 
});

export const carsSlice = createSlice({
  name: "cars",
  initialState,
  reducers: {
    setBg: (state, action) => {
      state.bg = action.payload;
    },
    openMobMnu: (state, action) => {
      state.mobMnu = true;
      document.querySelector('body').style.overflowY = 'hidden';
    },
    closeMobMnu: (state, action) => {
      state.mobMnu = false;
      document.querySelector('body').style.overflowY = 'auto';
    },
  },
  extraReducers: {
    [getCars.pending]: (state) => {
      //   state.cars = [];
    },
    [getCars.fulfilled]: (state, action) => {
      state.cars = action.payload;
    },
    [getCars.rejected]: (state, action) => {
      //   state.cars = [];
    },
  },
});

export const checkIsAuth = (state) => Boolean(state.auth.token);

export const { setBg, closeMobMnu, openMobMnu } = carsSlice.actions;
export default carsSlice.reducer;
